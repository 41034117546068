import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Contact = () => {
    return (
        <div className='contact-parent'>
            <Container className='contact-container'>
            <p className="contact-line">
                Have any feedback? Contact me at <a href="mailto:peter.ngoly@gmail.com">peter.ngoly@gmail.com</a>. Visit my <a href="https://github.com/ngolyp">GitHub</a> to see all of my work.</p>
        </Container>
        </div>
    )
}

export default Contact;