import React, { useEffect, useRef } from 'react';

import { TweenMax, TimelineLite, Power3, gsap, CSSPlugin } from 'gsap';

const Navbar = () => {

    gsap.registerPlugin(CSSPlugin);

    let navbar = useRef(null);

    let tl = new TimelineLite({ delay: 2.4 });


    const mouseOver = (el) => {
        TweenMax.to(el, .8, { y: -10, color: '#F89D12', ease: Power3.easeOut })

    }

    const mouseOut = (el) => {
        TweenMax.to(el, .8, { y: 0, color: '#f5f5f5', ease: Power3.easeOut })

    }

    useEffect(() => {

        const gmail = navbar.children[0];
        const linkedin = gmail.nextSibling;
        const github = linkedin.nextSibling;
        const fcc = github.nextSibling;

        tl
            .from(gmail, 1, {
                y: 44,
                ease: Power3.easeOut,
                opacity: 0
            })
            .from(linkedin, 1, {
                y: 44,
                ease: Power3.easeOut,
                opacity: 0
            }, 0)
            .from(github, 1, {
                y: 44,
                ease: Power3.easeOut,
                opacity: 0
            }, 0)
            .from(fcc, 1, {
                y: 44,
                ease: Power3.easeOut,
                opacity: 0
            }, 0)

    })


    return (
        <div className='navbar-parent' ref={el => navbar = el}>
            <a
                onMouseEnter={() => { mouseOver(navbar.children[0].children[0]) }}
                onMouseLeave={() => mouseOut(navbar.children[0].children[0])}
                className='navbar-link'
                href="mailto:peter.ngoly@gmail.com"
            >
                <i className="fas fa-envelope"></i>
            </a>
            <a
                onMouseEnter={() => { mouseOver(navbar.children[1].children[0]) }}
                onMouseLeave={() => mouseOut(navbar.children[1].children[0])}
                className='navbar-link' href="https://www.linkedin.com/in/peterngoly/" target='_blank'><i className="fab fa-linkedin"></i></a>
            <a
                onMouseEnter={() => { mouseOver(navbar.children[2].children[0]) }}
                onMouseLeave={() => mouseOut(navbar.children[2].children[0])}
                className='navbar-link' href="https://github.com/ngolyp" target='_blank'><i className="fab fa-github"></i></a>
            <a
                onMouseEnter={() => { mouseOver(navbar.children[3].children[0]) }}
                onMouseLeave={() => mouseOut(navbar.children[3].children[0])}
                className='navbar-link' href="https://www.freecodecamp.org/fcc7a6500ab-9813-4fbb-852a-f13b79e9376b" target='_blank'><i className="fab fa-free-code-camp"></i></a>
        </div>
    )
}

export default Navbar;