import React, { useEffect, useRef } from 'react';
import imgWeb from '../images/data.svg';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from "react-scroll";

import { TweenMax, TimelineLite, Power3 } from 'gsap';

const Header = () => {

    let content = useRef(null);
    let projectLink = useRef(null);
    let image = useRef(null);

    let tl = new TimelineLite({ delay: .8 });

    const mouseOver = (el) => {
        TweenMax.to(el.children[0], .8, { x: '15', color: '#F89D12', ease: Power3.easeOut })
    }

    const mouseOut = (el) => {
        TweenMax.to(el.children[0], .8, { x: '0', color: '#f5f5f5', ease: Power3.easeOut })

    }

    useEffect(() => {

        const headlineFirst = content.children[0].children[0];
        const headlineSecond = headlineFirst.nextSibling;
        const headlineThird = headlineSecond.nextSibling;
        const headlineProject = projectLink.children[0].children[0];
        const network = image.children[0];

        tl
            .from(headlineFirst, 1, {
                y: 44,
                ease: Power3.easeOut,
                opacity: 0
            })
            .from(headlineSecond, 1, {
                y: 44,
                ease: Power3.easeOut,
                opacity: 0
            }, 0.8)
            .from(headlineThird, 1, {
                y: 44,
                ease: Power3.easeOut,
                opacity: 0
            }, 0.8)
            .from(headlineProject, 1, {
                y: 44,
                ease: Power3.easeOut,
                opacity: 0
            }, 0.8)
            .from(network, 1, { x: 1280, ease: Power3.easeInOut }, .8)
            .from(network.firstElementChild, 1, { scale: 1.6, ease: Power3.easeOut }, 1.5)

    })



    return (
        <div className='header-parent'>
            <div className="header-parent-inner">
                <Container>
                    <Row className='justify-content-md-center'>
                        <Col md='12' lg='8' className='box1'>
                            <div className="header-content" ref={el => content = el}>
                                <h1>
                                    <div className="header-content-line hello">Hello!</div>
                                    <div className="header-content-line line2">My name is Peter.</div>
                                    <div className="header-content-line line3">A Full Stack Developer.</div>
                                </h1>
                                <div
                                    ref={el => projectLink = el}
                                    onMouseEnter={() => { mouseOver(projectLink.children[0].children[0]) }}
                                    onMouseLeave={() => mouseOut(projectLink.children[0].children[0])}
                                >
                                    <Link
                                        className="link"
                                        activeClass="active"
                                        to="project"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}>
                                        <h2 className='header-content-project'>Check out my Projects! <i className="fas fa-arrow-alt-circle-right"></i></h2>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col md='12' lg='4' className='box2'>
                            <div className="header-image" ref={el => image = el}>
                                <div className="header-image-web">
                                    <img src={imgWeb} alt='web' />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Header;