import React from 'react';

import Container from 'react-bootstrap/Container';

const Footer = () => {
    const d = new Date();
    const date = d.getFullYear();
    return(
        <div className="footer-parent">
            <Container className="footer-container">
            <p>Peter Ngo-Ly&copy; {date}</p>
            </Container>
        </div>
    )
}

export default Footer;