import React, { Component } from 'react';
import './App.scss';

import Navbar from './components/Navbar';
import Header from './components/Header';
import Project from './components/Project';
import Contact from './components/Contact';
import Footer from './components/Footer';

import Container from 'react-bootstrap/Container';


export class App extends Component {
  render() {
    return (
      <div className="app-container">
        <div className='landing-container'>
          <div className='navbar-container'>
            <div className="navbar-inner">
              <Navbar/>
            </div>
          </div>
          <Header/>
        </div>
        <Container>
          <div className="test">
        <hr className="hr-project" id="project"/>
        </div>
        </Container>
        <div>
        <Project/>
      </div>
      <div>
        <Contact/>
      </div>
      <Container>
      <hr className='hr-footer'/>
      </Container>
      <div>
        <Footer/>
      </div>
      </div>
    )
  }
}

export default App

