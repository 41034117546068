import React from 'react';

import weatherImg from '../images/weatherbuddy.PNG';
import kanyeImg from '../images/kanyequote.PNG';
import javaImg from '../images/javacustomerinfo.PNG';
import bbtImg from '../images/bbtapp.PNG';
import TACHImg from '../images/TACH.png';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const Project = () => {
    return (
        <div className="project-parent">
            <Container>

<div className="card-div1">
                <Row>
                    <Col md='12' lg='5' className="box3">
                    <div className="card-img-container">
                        <div className='card-img'>
                        <img className="img2" src={TACHImg} alt="TACH" />
                        </div>
                        </div>
                    </Col>
                    <Col lg='2' className="box4"></Col>
                    <Col md='12' lg='5' className="box5">
                    <div className="card-container">
                        <h1 className="project-title">TACH Automotive</h1>
                        <h3 className="project-description">
                            Designed a mobile responsive React website for TACH Automotive Limited in conjunction with Semantic UI front end framework.
                            Website uses Google Places API to display Google Reviews dynamically.
                                </h3>
                        <a href="https://www.tachautomotive.com" target="_blank"><p>Visit Website</p></a>
                    </div>
                    </Col>
                </Row>
                </div>

                <div className="card-div">
                <Row>
                    <Col md='12' lg='5' className="box3">
                    <div className="card-img-container">
                        <div className='card-img'>
                        <img className="img1" src={weatherImg} alt="weather" />
                        </div>
                        </div>
                    </Col>
                    <Col lg='2' className="box4"></Col>
                    <Col md='12' lg='5' className="box5">
                    <div className="card-container">
                        <h1 className="project-title">Weather Buddy</h1>
                        <h3 className="project-description">
                            A mobile responsive Angular application that allows the user to search the weather in any city.
                            The web application makes a RESTful API call to OpenWeatherMap and display the data in a friendly user interface.
                            Built with Angular, HTML5, CSS3, and Angular-Material.
                                </h3>
                        <a href="http://ngoly.dev.fast.sheridanc.on.ca/weather-buddy/" target="_blank"><p>Demo</p></a>
                        <a className='project-git' href="https://github.com/ngolyp/today-weather" target="_blank"><i className="fab fa-github-alt"></i></a>
                    </div>
                    </Col>
                </Row>
                </div>

<div className="card-div">
                <Row>
                    <Col md='12' lg='5' className="box3">
                        <div className="card-img-container">
                        <div className='card-img'>
                        <img className="img2" src={kanyeImg} alt="kanye" />
                        </div>
                        </div>
                    </Col>
                    <Col lg='2' className="box4"></Col>
                    <Col md='12' lg='5' className="box5">
                    <div className="card-container">
                        <h1 className="project-title">Yeezy Said What?!?!</h1>
                        <h3 className="project-description">
                        Kanye West has always been a polarizing figure. This random quote generator fetches his most famous quotes
                                by making a RESTful API call to kanye.rest and displays it on a friendly web page.
                                Built using React, HTML5, CSS3, JavaScript.
                                </h3>
                                <a href="https://ngolyp.github.io/yeezy-quote-generator/" target="_blank"><p>Demo</p></a>
                            <a className='project-git' href="https://github.com/ngolyp/yeezy-quote-generator" target="_blank"><i className="fab fa-github-alt"></i></a>
                    </div>
                    </Col>
                </Row>
</div>

<div className="card-div">
                <Row>
                    <Col md='12' lg='5' className="box3">
                        <div className="card-img-container">
                        <div className='card-img'>
                        <img className="img3" src={bbtImg} alt="bubbletea" />
                        </div>
                        </div>
                    </Col>
                    <Col lg='2' className="box4"></Col>
                    <Col md='12' lg='5' className="box5">
                    <div className="card-container">
                    <h1 className="project-title">BBT Online</h1>
                            <h3 className="project-description">
                                A bubble tea ordering application that allows customers to sign up and buy bubble tea online.
                                The application also features order history and the ability to edit orders by the employees.
                                This application works by connecting to a local database and then performs CRUD operations based on user's input.
                                Built with ASP.NET, ADO.NET with C#, HTML5, and CSS3.
                                </h3>
                                <a className='project-git' href="https://github.com/ngolyp/bubbletea-app" target="_blank"><i className="fab fa-github-alt"></i></a>
                    </div>
                    </Col>
                </Row>
</div>

<div className="card-div2">
                <Row>
                    <Col md='12' lg='5' className="box3">
                        <div className="card-img-container">
                        <div className='card-img'>
                        <img className="img4" src={javaImg} alt="java" />
                        </div>
                        </div>
                    </Col>
                    <Col lg='2' className="box4"></Col>
                    <Col md='12' lg='5' className="box5">
                    <div className="card-container">
                    <h1 className="project-title">Customer Information Application</h1>
                            <h3 className="project-description">
                                A customer information application that allows employees to
                                add, edit, delete and search for customers.
                                Built with Java, JavaFX, and Scene Builder.
                                </h3>
                                <a className='project-git' href="https://github.com/ngolyp/java-customerInfo-app" target="_blank"><i className="fab fa-github-alt"></i></a>
                    </div>
                    </Col>
                </Row>
</div>

            </Container>
        </div>
    )
}

export default Project;